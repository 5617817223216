import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [~5],
		"/bookmarks": [~6],
		"/chat": [~7,[2]],
		"/chat/[id]": [~8,[2],[,3]],
		"/icons": [9],
		"/login": [~10],
		"/login/popup": [11],
		"/logout": [12],
		"/organize": [~13],
		"/privacy-policy": [14],
		"/search": [~15],
		"/session": [~16],
		"/session/[id]": [~17],
		"/settings": [~18],
		"/share/[id]": [~19,[],[4]],
		"/terms-of-service": [20]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,

	reroute: (() => {})
};

export { default as root } from '../root.js';